import React from 'react'
import { Link } from 'gatsby'
import { useSelector, shallowEqual } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import {
  Blanket,
  BlanketTitle,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Button } from '../../components/Button'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { BoxLinks } from '../../components/BoxLinks'
import { Container } from '../../components/Container'

import { Calendar } from '../../reducers/calendar'

import { getCalendar } from '../../selectors/calendar'

import * as classes from './ticketing.module.scss'

const ticketingLinks = [
  { to: '/accredito-tessere-fipconi', name: 'Accrediti FIP/CONI' },
  { to: '/richiesta-accrediti-stagionale', name: 'Accrediti stampa' },
  { to: '/richiesta-accredito-disabili', name: 'Accrediti per disabili' },
]

const getNextHomeMatch = (schedule: Calendar, currentDay?: number) => {
  if (currentDay !== undefined && currentDay >= 0) {
    return schedule[currentDay].find(
      (match) => match.homeTeamId === process.env.REACT_APP_RE_TEAM_ID
    )
  }
}

const Ticketing = () => {
  const schedule = useSelector(getCalendar, shallowEqual)
  const { ticketingImage } = useStaticQuery(graphql`
    query Ticketing {
      ticketingImage: file(name: { eq: "pianta-palabigi" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Blanket>
      <SEO title={'Biglietteria'} />

      <BlanketBackground>
        <BlanketTitle>Ticketing</BlanketTitle>

        <Section className={classes.buttonHeaderContainer} withContainer>
          <Button as={Link} to={'/calendario'} variant={'light'}>
            Vai al calendario partite
          </Button>
        </Section>

        {/*
        <Section>
          <Container size={'small'}>
            <p>
              <strong>
                MODALITA’ DI VENDITA DEI BIGLIETTI PER GLI INCONTRI CASALINGHI
                DELLA UNAHOTELS RE STAGIONE 24/25
              </strong>
            </p>

            <p>
              <strong>DOVE ACQUISTARE I BIGLIETTI</strong>
            </p>

            <p>I tagliandi sono in vendita:</p>
            <ul>
              <li>
                Allo “stoRE” di piazza Prampolini (RE) nei giorni di apertura
                del negozio: martedì, venerdì (10/13 e 16/19) e sabato dalle ore
                10 alle ore 19.
              </li>
              <li>
                Presso la sede della Pallacanestro Reggina Srl in Via Martiri
                della Bettola 47 (RE) dal lunedì al venerdì dalle ore 10 alle
                ore 13 nei giorni di aperture delle prevendite.
              </li>
              <li>
                Online sul portale oppure presso le ricevitorie Vivaticket.
              </li>
              <li>
                Nella biglietteria del PalaBigi il giorno della partita da
                un’ora e quindici minuti prima della palla a due.
              </li>
            </ul>

            <p>
              L’inizio delle prevendite, eventuali modifiche a luoghi e orari o
              aperture straordinarie verranno comunicate e si troveranno nella
              sezione NEWS.
            </p>
          </Container>
        </Section>

        <Section withContainer>
          <Row>
            <Col
              className={classes.buttonContainer}
              xs={12}
              md={{ span: 5, offset: 1 }}
            >
              <Button
                as={Link}
                to={'/accessibilita-al-palabigi'}
                variant={'light'}
                className={classes.button}
              >
                Accessibilità al Palabigi
              </Button>
            </Col>

            <Col className={classes.buttonContainer} xs={12} md={5}>
              <Button
                as={'a'}
                href={'#'}
                target={'_blank'}
                variant={'light'}
                rel={'noreferrer noopener'}
                className={classes.button}
                disabled
              >
                REGOLAMENTO D’USO DELL’IMPIANTO
              </Button>
            </Col>
          </Row>
        </Section>

        <Section className={classes.ticketingContainer} withContainer>
          <Row>
            <Col xs={12} md={5}>
              <p>
                <strong>I PREZZI</strong>
              </p>

              <p>
                <strong>Serie A:</strong>
              </p>

              <ul>
                <li>Parterre 1^fila: euro 95</li>
                <li>Parterre: euro 85</li>
                <li>Tribuna: euro 55</li>
                <li>Tribuna Under 18: euro 45</li>
                <li>Distinti: euro 35</li>
                <li>Distinti Under 18: euro 25</li>
                <li>Gradinata: euro 30</li>
                <li>Gradinata Under 18: euro 20</li>
                <li>Curva non numerata: euro 20</li>
                <li>Curva non numerata Under 18: euro 15</li>
              </ul>

              <p>
                Nel corso della stagione i prezzi dei biglietti potranno subire
                modifiche
              </p>
            </Col>

            <Col className={classes.arenaImage} xs={12} md={7}>
              <GatsbyImage fluid={ticketingImage.childImageSharp.fluid} />
            </Col>
          </Row>
        </Section>
        */}

        <BoxLinks links={ticketingLinks} />
      </BlanketBackground>
    </Blanket>
  )
}

const WorkInProgress = () => (
  <Blanket dark={false} noPadding>
    <SEO title='404: Not found' />

    <BlanketContent>
      <Container>
        <h3>Pagina in costruzione</h3>

        <Link to={'/'}>Torna alla home</Link>
      </Container>
    </BlanketContent>
  </Blanket>
)

export default Ticketing
